import logo from "@src/assets/images/logo/elitebeauty.svg";
import logoWhite from "@src/assets/images/logo/logo_white.png";
import React, { useEffect, useState } from "react";

const SpinnerComponent = () => {
    const skin = localStorage.getItem("skin");
    const [isSkin, setIsSkin] = useState(logo);
    useEffect(() => {
        if (skin === `"light"` || skin === "light") {
            setIsSkin(logo);
        } else {
            setIsSkin(logoWhite);
        }
    }, [skin]);

    return (
        <div className="fallback-spinner app-loader ">
            <img className="fallback-logo" src={isSkin} alt="logo" />
            <div className="loading">
                <div className="effect-1 effects"></div>
                <div className="effect-2 effects"></div>
                <div className="effect-3 effects"></div>
            </div>
        </div>
    );
};

export default SpinnerComponent;
